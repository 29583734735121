// External
import React, { useEffect, useState } from 'react';

// Internal
import CheckoutButton from '../CheckoutButton';
import CartDiscount from './CartDiscount';
import CheckoutButtonWithoutUpsell from '../CheckoutButtonWithoutUpsell';

import { getPrice } from '../../utils/helpers';


const CartFooter = ({
	hasItems,
	cart,
	productsList,
	closeCart,
	isOpen,
	isCartLimitExceeded,
	isCartBlocked,
	modalOpen,
	cartStories,
}) => {
	const cartProducts = productsList
		?.filter((product) =>
			cart?.lines?.edges.some(
				(element) =>
					element.node.merchandise.product.handle === product.handle
			)
		)
		.map((product) => {
			const correspondingItem = cart?.lines?.edges.find(
				(element) =>
					element.node.merchandise.product.handle === product.handle
			);
			return { ...product, quantity: correspondingItem.node.quantity };
		});
	const extraDiscount = cartProducts
		?.filter((product) => product.variants[0].compareAtPrice !== null)
		.reduce(
			(previous, next) =>
				previous +
				(parseFloat(next.variants[0].compareAtPrice) -
					parseFloat(next.variants[0].price)) *
				next.quantity,
			0
		);
	const priceWithoutDelivery = cart?.cost?.totalAmount?.amount === cart?.cost?.subtotalAmount.amount ?
		cart?.cost?.totalAmount?.amount : cart?.cost?.subtotalAmount.amount;
	const [price, setPrice] = useState(
		getPrice(priceWithoutDelivery, cart, 2)
	);

	let discount;
	if (cart?.cost && cart?.lines) {
		discount =
			parseFloat(priceWithoutDelivery) -
			cart.lines.edges.reduce(
				(previous, next) =>
					previous +
					parseFloat(next.node.merchandise.priceV2.amount) *
					parseFloat(next.node.quantity),
				0
			) -
			parseFloat(extraDiscount);
	}
	const discountPrice = getPrice(discount, cart, 2);
	const delivery = priceWithoutDelivery
		? 35.01 - priceWithoutDelivery
		: 35.01;
	const isFreeDelivery =
		delivery <= 0 ||
		cart?.lines?.edges.filter((item) => item.node.sellingPlanAllocation)
			.length;
	const deliveryPrice = getPrice(delivery, cart, 2);

	useEffect(() => {
		if (cart) {
			let sumPrice = 0;
			const newItems = cart?.lines?.edges?.filter(
				(el) =>
					el.node.attributes.length > 0 &&
					el.node.attributes.some(
						(attr) => attr['key'] === '_new_price'
					)
			);
			for (const item of newItems) {
				const newPrice = +item.node.attributes.find(
					(attr) => attr['key'] === '_new_price'
				)['value'],
					oldPrice = +item.node.merchandise.priceV2.amount,
					updatedPrice = (oldPrice - newPrice) * item.node.quantity;
				sumPrice += updatedPrice;
			}
			if (cart?.cost)
				setPrice(
					getPrice(
						sumPrice
							? priceWithoutDelivery - sumPrice
							: priceWithoutDelivery,
						cart,
						2
					)
				);
		}
	}, [cart, isOpen]);

	return (
		<div className='cart-footer'>
			{hasItems && (
				<>
					<div className='cart-footer__promo'>
						{!isFreeDelivery ? (
							<span className='cart-footer__delivery'>
								Spend {deliveryPrice} more to qualify for free
								delivery
							</span>
						) : (
							<span className='cart-footer__delivery'>
								You've qualified for free standard delivery
							</span>
						)}
					</div>
					<CartDiscount setPrice={setPrice} />
					{discount < 0 && (
						<div className='cart-footer__total'>
							<span>Discount</span>
							{!isCartBlocked && <span>{discountPrice}</span>}
						</div>
					)}
					<div className='cart-footer__total'>
						<span>Total</span>
						{!isCartBlocked && <span>{price}</span>}
					</div>
					<div className='cart-footer__buttons'>
						<CheckoutButton
							total={parseInt(cart.cost.totalAmount.amount)}
							isCartLimitExceeded={isCartLimitExceeded}
							modalOpen={modalOpen}
							productsList={productsList}
							isCartBlocked={isCartBlocked}
							cartStories={cartStories}
						/>
						<CheckoutButtonWithoutUpsell
							total={parseInt(cart.cost.totalAmount.amount)}
							isCartLimitExceeded={isCartLimitExceeded}
							modalOpen={modalOpen}
							productsList={productsList}
							isCartBlocked={isCartBlocked}
						/>
						<button
							type='button'
							className='cart-footer__continue'
							onClick={closeCart}
						>
							<span>continue shopping</span>
						</button>
					</div>
					<div className='cart-footer__info'>
						<p>
							Need some help? Check out our
							<a href='/faq '>
								{' '}
								FAQ page
							</a>
						</p>
					</div>
				</>
			)}
		</div>
	);
};

export default CartFooter;
